.vin__number {
  @include flex(center, initial);

  button {
    @include font-style(1.6rem, 500, normal, 2.1rem, $coal);
    font-family: "Core Mellow Medium";
    background-color: $primary-color;
    color: $white;
    padding: 0.8rem 3.6rem;
  }

  input {
    @include font-style(1.6rem, 500, normal, 2.1rem, $coal);
    font-family: "MiloComp-Medium";
    border: 0.1rem solid $coal;
    margin-right: 1.1rem;
  }

  input {
    width: 39.1rem;
    padding: 0.7rem 1.5rem;
    outline: none;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 100rem $white inset !important;
    }

    /* Works for Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Works for Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }

  &__warning,
  &__found,
  &__error {
    @include font-style(1.6rem, 500, normal, 2rem, $dark-grey);
    font-family: "Core Mellow Regular";
    letter-spacing: 0.0025em;
    margin: 2.4rem 0 2.7rem 0;

    span {
      color: $black;
      font-family: "Core Mellow Medium";
    }
  }
}
